import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uuid: null,
  firstName: null,
  lastName: null,
  email: null,
  auth: {
    uid: null,
    client: null,
    expireIn: null,
    token: null
  }
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    saveUserInfo(state, action) {
      return { ...action.payload };
    },
    logout(state) {
      return { ...initialState };
    },
  },
});

export const { saveUserInfo, logout } = authSlice.actions;
export default authSlice.reducer;
